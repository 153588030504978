<template>
  <div id="account-management">
    <NForm inline label-placement="left">
      <NFormItem label="搜索标题：">
        <NInput
          maxlength="50"
          show-count
          clearable
          v-model:value="formValue.name"
          @update:value="ChangeTitle"
        />
      </NFormItem>
      <NFormItem label="类型：">
        <div style="width: 200px">
          <NSelect :options="typeOptions" v-model:value="formValue.status" />
        </div>
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <n-button type="primary" class="account-btn" @click="NewlyAdded" v-if="ownUrlPermission('system-power/roles-create')">新增角色</n-button>
    <div class="account-null-btn"></div>
    <n-data-table
      remote
      ref="table"
      :single-line="false"
      :bottom-bordered="true"
      :columns="columns"
      :data="Datas"
      :loading="false"
      :pagination="pagination"
      @update:page-size="handlePageChange"
      @update:page="handlePageSizeChange"
    />
    <!-- :row-key="rowKey" -->
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h } from "vue";
import { NSpace, NButton, NTime, useMessage } from "naive-ui";

import teachingTypesMap from "@/enumerators/account-status.js";
import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import { PostSystemPowerRoles, PostsyStemPowerRoleStatus, SystemPowerRoleDelete } from "@/api/admin-role.js";
import { toRaw } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import PageLoading from "@/components/PageLoading/index.vue";
import ownUrlPermission from '@/utils/own-url-permission.js';

// import {
//   getTeachingList,
//   delTeaching,
//   batDelTeaching,
// } from "@/api/teaching.js";
const message = useMessage();
const router = useRouter();
// 总数据
const Datas = ref([])
const columns =  [
    {
      title: "角色",
      key: "label",
    },
     {
      title: '状态',
      render: row => h(
        'span',
        null,
        { default: () => getResourceName(row.status) },
      )
    },
    {
      title: "备注",
      key: "remark",
    },
    {
      title: '操作',
      width: 300,
      key: "status",
      render: row => h(
        NSpace,
        null,
        {
          default: () => Operation(row)
        }
      )
    }
  ];

// 数据请求 / 分页所需--------------
const { SUCCESS } = resStatusEnum;
const loading = ref(false);
const select = ref(null)  // 新增时所选的权限ID         row.all_menus     
const public_menu_id = ref(null)  // 新增时所选的公用权限ID     row.system_all_menus

// 数据初始化
function updateList(data){
  loading.value = true;
  // 请求列表数据
  PostSystemPowerRoles(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Datas.value = res.data.list
        select.value = res.data.all_menus
        public_menu_id.value = res.data.system_all_menus
        updateItemCount(res.data.total)
      }
    })
    .catch((reject) => {});
};
updateList();

// 状态
 function getResourceName(status) {
  return accountType[status] || '';
}

//操作
 function Operation(row) {
  let tempArr = [];
  let arr1 = h(NButton, { text: true, type: 'primary', onClick: () => DisableEnable(row) }, { default: () => '启用' })
  let arr2 = h(NButton, { text: true, type: 'primary', onClick: () => toAddLabel(row) }, { default: () => '权限设置' })
  let arr3 = h(NButton, { text: true, type: 'error', onClick: () => toDelLabel(row) }, { default: () => '删除' })
  let arr4 = h(NButton, { text: true, type: 'error', onClick: () => DisableEnable(row) }, { default: () => '停用' })
  if(row.status=="0"){
    // 角色状态改变
    if (ownUrlPermission('system-power/role-status')){
      tempArr.push(arr1)
    }
    // 角色编辑
    if (ownUrlPermission('system-power/role-update')){
      tempArr.push(arr2)
    }
    // 角色删除
    if (ownUrlPermission('system-power/role-delete')){
      tempArr.push(arr3)
    }
    return tempArr
  }else{
    // 角色状态改变
    if (ownUrlPermission('system-power/role-status')){
      tempArr.push(arr4)
    }
    // 角色编辑
    if (ownUrlPermission('system-power/role-update')){
      tempArr.push(arr2)
    }
    return tempArr
  }
}

const addLabelRef = ref(null);
// 编辑
function toAddLabel(row) {
  let id = row.value
  let select = toRaw(row.role_menus)
  let pselect = toRaw(row.system_check_menus)
  router.push({
    path:'/system-power/roles/add/',
    query:{id,select,pselect}
  })
}

// 新增
function NewlyAdded() {
  // console.log("新增1",select.value)
  router.push({
    path:'/system-power/roles/add',
    query:{select:select.value,pselect:public_menu_id.value}
  })
}

 // 删除
function toDelLabel(row) {
  const dialog = window.$dialog;
 
   let data = {
    'AdminRole[id]':row.value
  }
  dialog.warning({
    title: '删除确认',
    content: `确定删除角色:“${row.label}”？`,
    positiveText: '确定',
    negativeText: '取消',
    onPositiveClick: () => {
      loading.value = true;
      SystemPowerRoleDelete(data).then((res) => {
        if (res.code === SUCCESS) {
          loading.value = false;
          message.info(res.msg);
          // 成功后 调用接口刷新页面
          updateList()
        }
      })
      .catch((reject) => {})
      // console.log("data",data)
    },
    onNegativeClick: () => {}
  });

  // 账号删除接口
    
}

  // 停用启用
  function DisableEnable(row){
    loading.value = true;
    var formDatas = new FormData()
    let data = {
    'AdminRole[id]':row.value,
    'AdminRole[status]':row.status == '0'?1 : 0
    }
    PostsyStemPowerRoleStatus(data).then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        message.info(res.msg);
        // 成功后 调用接口刷新页面
        updateList()
      }
    })
    .catch((reject) => {});
  }

// 翻页 搜索参数集
var ParameterSet = {}

// 监控标题输入
 function ChangeTitle(title) {
  pagination.page = 1;
  formValue.page = 1;
}

// 搜索
function handleSearch () {
  ParameterSet = toRaw(formValue)
  if(ParameterSet.status=="1"){
    ParameterSet.status = 0
  }
  else if(ParameterSet.status=="2"){
    ParameterSet.status = 1
  } 
  else if(ParameterSet.status=="3"){
    ParameterSet.status = 2
  }
  updateList(ParameterSet)
}

const pagination = reactive({
  page: 1,
  itemCount: 0,
  pagesize: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;

};
const handlePageChange = (pagesize) => {
  pagination.pagesize = pagesize;
  formValue.pagesize = pagesize;
  ParameterSet = toRaw(formValue)
  updateList(ParameterSet);
};
const handlePageSizeChange = (page) => {
  pagination.page = page;
  formValue.page = page;
  ParameterSet = toRaw(formValue)
  updateList(ParameterSet);
};


// 分页结束-------------------

const typeOptions = (() =>
  Object.keys(teachingTypesMap).map((value) => ({
    label: teachingTypesMap[value],
    value,
  })))();
const getDefaultFormValue = () => ({
  name: "",
  status: null,
  page: 1,
  pagesize: 10,
});
const formValue = reactive(getDefaultFormValue());
const getSearchParams = () => {
  const params = {};
  Object.keys(formValue).forEach((key) => {
    const value = formValue[key];
    value && (params[key] = value);
  });
  return params;
};

const emit = defineEmits(["list-update"]);


const handleClear = () => {
  Object.assign(formValue, getDefaultFormValue());
  handleSearch();
};

defineExpose({
  getSearchParams,
});

</script>

<style scoped>
#account-management {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.account-btn {
  float: right;
}
.account-null-btn {
  height: 50px;
  width: 120px;
}
</style>